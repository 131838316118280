const aws = {
  region: "eu-west-1",
  identityPoolId: "eu-west-1:d6a06f60-f9a9-495f-a5b1-e68251f6ff5e",
  userPoolId: "eu-west-1_EspVaysLf",
  userPoolClientId: "656tbe2egucuplndhnmn5bnga4",
  userManagementAppsyncEndpoint: "https://lqgx2v5ofbdxjnss4xfez55l4m.appsync-api.eu-west-1.amazonaws.com/graphql",
  stockManagementAppsyncEndpoint: "https://hdsfp57p2vfhxcosa4puerkhee.appsync-api.eu-west-1.amazonaws.com/graphql",
  productionManagementAppsyncEndpoint: "https://3jkg56vfvnag5pvk7luhejmbxy.appsync-api.eu-west-1.amazonaws.com/graphql"
}
export default aws
